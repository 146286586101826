import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
// 引入elementUI
Vue.use(ElementUI, {size: "mini"});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
