import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
},
{
    path: '/',
    name: 'layout',
    component: () => import('../views/Layout.vue'),
    children:[
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/Home.vue')
        },
        {
            path: '/type',
            name: 'type',
            component: () => import('../views/Type.vue')
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('../views/User.vue')
        },
        {
            path: '/echart',
            name: 'echart',
            component: () => import('../views/Echart.vue')
        }
    ]
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由守卫
router.beforeEach((to ,from, next) => {
  if (to.path ==='/login') {
    next();
  }
  const user = localStorage.getItem("userInfo");
  if (!user && to.path !== '/login') {
    return next("/login");
  }
  next();
})

export default router
